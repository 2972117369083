<template>
  <div id="localidades" class="mt-4">
    <h4 class="text-primary mt-3 mb-3 font-weight-bold">Localidades</h4>
    <div class="mb-3 text-right">
      <button
        v-if="perfil == 1"
        data-toggle="modal"
        data-target="#nuevo"
        class="btn btn-success btn-rounded btn-sm"
      >
        <i class="fa fa-plus-circle"></i> Nueva localidad
      </button>
    </div>
    <div class="table-responsive pt-3">
      <table class="table my_table table-bordered table-hover">
        <thead>
          <tr>
            <th class="text-center" style="width: 5%">#</th>
            <th class="text-center" style="width: 5%">Id v1</th>
            <th class="text-center" style="width: 5%">Id v2</th>
            <th>Localidad</th>
            <th v-if="evento.id_tipo_lugar != 0">Localidad asociada</th>
            <th style="width: 5%">Vendidas</th>
            <th class="text-center" style="width: 10%">Color</th>
            <th v-if="perfil == 1" class="text-center" style="width: 5%">
              Opciones
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(localidad, index_localidad) in localidades"
            v-bind:key="index_localidad"
          >
            <td class="text-center">
              {{ index_localidad + 1 }}
            </td>
            <td class="text-center" style="width: 5%">
              {{ localidad.id_localidad_shop }}
            </td>
            <td class="text-center" style="width: 5%">
              {{ localidad.id_localidad }}
            </td>
            <td>
              <span v-if="!edit || index_select != index_localidad">
                {{ localidad.localidad }}
              </span>
              <input
                v-else
                v-model="localidad.localidad"
                class="form-control"
                type="text"
              />
            </td>
            <td v-if="evento.id_tipo_lugar != 0">
              <span v-if="!edit || index_select != index_localidad">
                <span v-if="localidad.localidad_tipo_lugar != null">
                  {{ localidad.localidad_tipo_lugar.localidad_tipo_lugar }}
                </span>
              </span>
              <select
                v-else
                v-model="localidad.id_localidad_tipo_lugar"
                class="form-control"
              >
                <option
                  v-for="(
                    localidad_tipo_lugar, index_localidad_tipo_lugar
                  ) in localidades_tipo_lugar"
                  v-bind:key="index_localidad_tipo_lugar"
                  v-bind:value="localidad_tipo_lugar.id_localidad_tipo_lugar"
                >
                  {{ localidad_tipo_lugar.localidad_tipo_lugar }}
                </option>
              </select>
            </td>
            <td class="text-center">
              <span v-if="!edit || index_select != index_localidad">
                {{ localidad.vendidas }}
              </span>
              <input
                v-else
                v-model="localidad.vendidas"
                class="form-control text-center"
                type="text"
              />
            </td>
            <td class="text-center">
              <span v-if="!edit || index_select != index_localidad">
                <i
                  class="fa fa-circle 2x"
                  v-bind:style="'color:' + localidad.color"
                ></i>
              </span>
              <input
                v-else
                v-model="localidad.color"
                class="form-control text-center"
                type="color"
              />
            </td>
            <td v-if="perfil == 1" class="text-center" style="width: 10%">
              <span v-if="!edit || index_select != index_localidad">
                <button
                  v-on:click="load_editar(index_localidad)"
                  class="btn btn-primary btn-xs"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  v-on:click="load_eliminar(index_localidad)"
                  data-toggle="modal"
                  data-target="#eliminar"
                  class="btn btn-danger btn-xs"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </span>
              <span v-else>
                <a
                  v-bind:id="'btn_save_' + index_localidad"
                  v-on:click="update_localidad"
                  class="btn btn-success btn-xs"
                >
                  <i class="fa fa-save"></i>
                </a>
                <a
                  v-on:click="edit = false"
                  class="btn btn-outline-dark btn-xs"
                >
                  <i class="fa fa-times"></i>
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal fade" id="nuevo">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form v-on:submit.prevent="set_localidad">
            <div class="modal-header">
              <h5 class="modal-title text-primary">Nueva localidad</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Localidad</label>
                <input
                  v-model="nuevo.localidad"
                  type="text"
                  class="form-control my-form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label>Vendidas</label>
                <input
                  v-model="nuevo.vendidas"
                  type="text"
                  class="form-control my-form-control"
                  required
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-dark btn-rounded btn-sm light"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-success btn-sm btn-rounded">
                <i class="fa fa-save"></i> Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="eliminar">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form v-on:submit.prevent="delete_localidad">
            <div class="modal-header">
              <h5 class="modal-title text-danger">Eliminar localidad</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="msg_eliminar.type == null">
                <div class="text-danger">
                  <i class="fa fa-question-circle"></i> ¿Desea eliminar el
                  registro de la localidad
                  <strong>{{ localidad.localidad }} </strong>?
                </div>
                <div class="text-center mt-3">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm btn-rounded"
                  >
                    Sí
                  </button>
                  <button
                    v-on:click="localidad = []"
                    type="button"
                    class="btn btn-outline-dark btn-rounded btn-sm light ml-1"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
              <div v-if="msg_eliminar.type && msg_eliminar.type != null">
                <div class="alert alert-info">
                  <i class="fa fa-check-circle"></i> La localidad ha sido
                  eliminada.
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Localidades",
  metaInfo: {
    title: "Localidades del evento - Taqui",
  },
  data() {
    return {
      loading: true,
      loading_upload: false,
      id_evento: null,
      localidades: [],
      edit: false,
      index_select: null,
      localidad: [],
      nuevo: {
        localidad: null,
        vendidas: 0,
      },
      msg_eliminar: {
        type: null,
        text: null,
      },
      form_upload: false,
      file: [],
      hay_archivo: false,
      localidades_tipo_lugar: [],
    };
  },
  props: {
    evento: Object,
    perfil: String,
  },
  created: function () {
    this.get_localidades();
    if (this.evento.id_tipo_lugar != 0) {
      this.get_localidades_tipo_lugar();
    }
  },
  methods: {
    get_localidades: function () {
      this.id_evento = this.$route.params.id_evento;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      this.page = this.$route.params.page;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
      };
      axios
        .get(this.$base_url + "eventos/localidades/" + this.id_evento, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.localidades = response.data.data;
          }
        })
        .catch()
        .finally();
    },
    get_localidades_tipo_lugar: function () {
      var id_tipo_lugar = this.evento.id_tipo_lugar;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      this.page = this.$route.params.page;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
      };
      axios
        .get(
          this.$base_url + "eventos/localidades_tipo_lugar/" + id_tipo_lugar,
          config,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.localidades_tipo_lugar = response.data.data;
          }
        })
        .catch()
        .finally();
    },
    set_localidad: function () {
      this.msg_nuevo = {
        type: null,
        msg: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_evento: this.id_evento,
        localidad: this.nuevo.localidad,
        vendidas: this.nuevo.vendidas,
      };
      const vm = this;
      axios
        .post(this.$base_url + "eventos/set_localidad", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.nuevo = {
              localidad: null,
            };
            vm.get_localidades();
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Nueva",
              text: "Se ha creado la localidad.",
            });
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Nueva",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Nueva",
            text: error,
          });
        });
    },
    load_editar: function (index) {
      this.localidad = this.localidades[index];
      this.index_select = index;
      this.edit = true;
    },
    update_localidad: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_localidad: this.localidad.id_localidad,
        id_localidad_tipo_lugar: this.localidad.id_localidad_tipo_lugar,
        localidad: this.localidad.localidad,
        vendidas: this.localidad.vendidas,
        color: this.localidad.color,
      };
      const vm = this;
      axios
        .put(this.$base_url + "eventos/update_localidad", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Localidades",
              text: "Se ha actualizado la localidad.",
            });
            vm.edit = false;
            vm.get_localidades();
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Localidades",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Localidades",
            text: error,
          });
        });
    },
    load_eliminar: function (index) {
      this.localidad = this.localidades[index];
    },
    delete_localidad: function () {
      this.msg_eliminar = {
        type: null,
        text: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_localidad: this.localidad.id_localidad,
      };
      const vm = this;
      axios
        .put(this.$base_url + "eventos/eliminar_localidad", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.msg_eliminar = {
              type: true,
              text: "Localidad eliminada.",
            };
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Localidades",
              text: "Se ha eliminado la localidad.",
            });
            vm.localidad = [];
            vm.get_localidades();
          } else {
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Localidades",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Localidades",
            text: error,
          });
        });
    },
  },
};
</script>